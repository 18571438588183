import React, { useState, useEffect } from 'react'
import {
  Card,
  Table,
  Tag,
  Tooltip,
  message,
  Button,
  Divider,
  Form,
  Badge,
  Dropdown,
  Menu
} from 'antd'
import { EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { navigate } from 'gatsby'
import {
  assendingSort,
  FetchParentData,
  FetchLocalData,
  IndustryType
} from '../../utils/helper'
import moment from 'moment'
import { connect } from 'react-redux'
import AppLayout from '../../components/layout'
import ServiceForm from './comp/Form'
import {
  addServices,
  getServiceList,
  updateStatus,
  updateServices,
  deleteServices
} from '../../actions/serviceAction'
import Add from '../../images/new/plus_icon_yellow.svg'
import ServiceView from './comp/ServiceView'
import './service.css'
import { Routes } from '../../utils/route'
import { Spin } from 'antd'
import { NumToStr } from '../../utils/helper'
import PageHeader from '../../components/pageHeader/PageHeader'
import AddService from './comp/AddService'
const assendingEq = assendingSort
const Service = props => {
  const [state, setState] = useState({
    users: [],
    isShowService: false,
    selectedServiceToShow: null
  })
  const [form] = Form.useForm()
  const [id, setId] = useState(null)
  const [price, setPrice] = useState('')
  const [formEdit, setFormEdit] = useState(false)
  const [tax, setTax] = useState(0)
  const [serviceGroup, setServiceGroup] = useState([])
  const [selectedServiceGroup, setSelectedServiceGroup] = useState(null)
  const [service, setService] = useState([])
  const [selectedService, setSelectedService] = useState(null)
  const [subService, setSubService] = useState([])
  const [selectedSubService, setSelectedSubService] = useState(null)
  const [haz, setHaz] = useState(undefined)
  const [validateFrom, setValidateFrom] = useState(moment(Date.now()))
  const [validateTo, setValidateTo] = useState(moment())
  const [remark, setRemark] = useState('')
  const [loading, setLoading] = useState(true)
  const [load, setLoad] = useState(true)
  const [hazData, setHazData] = useState([])
  const [mandatoryHaz, setMandatoryHaz] = useState(false)
  const [eqCategory, setEqCategory] = useState(null)
  const [eqTypeData, setEqTypeData] = useState(null)
  const [eqType, setEqType] = useState(null)
  const [visible, setVisible] = useState(false)
  // const {serviceList}=API
  const add_years = (dt, n) => {
    return new Date(dt.setFullYear(dt.getFullYear() + n))
  }
  useEffect(() => {
    const callback = val => {
      if (val === true) {
        setLoading(false)
        props.getServiceList()
        EquipmentCategory()
        getDataFromLocalStorage()
      }
    }
    Routes('manager', callback)
  }, [])

  const filterData = val => {
    let serviceGroup = []
    let service = []
    let subService = []
    let newArr = []
    val.forEach(function (item, index) {
      var _serviceGroup = serviceGroup.filter(
        x => x.value == item.service_group_id
      )
      if (_serviceGroup.length <= 0) {
        serviceGroup.push({
          id: item.service_group_id,
          name: item.service_group_name.param_description,
          value: item.service_group_id
        })
      }

      let _service = service.filter(x => x.value == item.service_id)
      if (_service.length <= 0) {
        service.push({
          key: index.toString(),
          parent_id: item.service_group_id,
          name: item.service_name.param_description,
          value: item.service_id
        })
      }
      let _subService = subService.filter(x => x.value == item.sub_service_id)
      // if (_subService.length <= 0) {
      subService.push({
        ...item,
        parent_id: item.service_id,
        name: item.sub_service_name?.param_description,
        value: item.sub_service_id
      })
      // }
    })

    service.forEach(item => {
      if (!item.child) {
        item.child = []
      }
      subService.forEach(_item => {
        if (item.value === _item.parent_id) {
          const equipment_type = _item?.equipment_type_id
            ? _item?.equipment_type_id
            : ''
          item.child.push({
            ..._item,
            equipment_type: FetchLocalData(
              FetchParentData(equipment_type),
              equipment_type
            )
          })
        }
      })
    })
    serviceGroup.forEach(group => {
      if (!group.child) {
        group.child = []
      }
      service.forEach(_item => {
        if (group.value === _item.parent_id) {
          group.child.push(_item)
        }
      })
    })
    setLoad(false)
    setState({ ...state, users: serviceGroup })
  }

  useEffect(() => {
    filterData(props.list)
  }, [props.list])

  useEffect(() => {
    if (visible === false) {
      setFormEdit(false)
      form.resetFields()
      setSelectedServiceGroup(null)
    }
  }, [visible])

  useEffect(() => {
    const _users = [...users]
    _users.push(props.newUserData)
    setState({ ...state, users: _users })
  }, [props.newUserData])

  useEffect(() => {
    const _users = [...users]
    if (props.updateUserData !== undefined) {
      _users.filter((item, i) => {
        if (parseInt(props.updateUserData.user_id) === parseInt(item.user_id)) {
          _users[i] = props.updateUserData
        }
      })
    }

    setState({ ...state, users: _users })
  }, [props.updateUserData])

  useEffect(() => {
    const _users = [...users]
    _users &&
      _users.map(item => {
        if (item.user_id === props.userId) {
          item.status = props.user_status
        }
      })
    setState({ ...state, users: _users })
  }, [props.userId, props.user_status])

  const getDataFromLocalStorage = async () => {
    const data =
      (await typeof window) !== 'undefined' ? localStorage.getItem('data') : ''
    if (data) {
      let jsonParse = JSON.parse(data)

      let services = []
      let serviceGroup = []
      jsonParse.TabParams.map(item => {
        if (
          item.param_key === 'service_type' &&
          item.param_name !== 'Consumable_Type'
        ) {
          services.push(item)
        } else if (
          item.param_key === 'service_group' &&
          item.param_description !== 'Other Service'
        ) {
          if (IndustryType() === 'Food_Grade') {
            if (
              [2080, 2090, 2040, 2070, 2050, 2060].includes(item.param_value)
            ) {
              serviceGroup.push(item)
            }
          } else {
            serviceGroup.push(item)
          }
        }
      })
      services.sort((a, b) =>
        a.param_description > b.param_description
          ? 1
          : b.param_description > a.param_description
          ? -1
          : 0
      )
      serviceGroup.sort((a, b) =>
        a.param_description > b.param_description
          ? 1
          : b.param_description > a.param_description
          ? -1
          : 0
      )
      setService(services)
      setServiceGroup(serviceGroup)
    }
  }

  function assendingSort (val) {
    return val.sort((a, b) =>
      a.param_name.toUpperCase() > b.param_name.toUpperCase()
        ? 1
        : b.param_name.toUpperCase() > a.param_name.toUpperCase()
        ? -1
        : 0
    )
  }
  const setServices = (e, isEdit) => {
    const data = localStorage.getItem('data')
    if (data) {
      let jsonParse = JSON.parse(data)
      let services = []
      jsonParse.TabParams.map(item => {
        if (item.param_key === 'service_type') {
          if (item.parent_id === e) {
            services.push(item)
          }
          if ([2040, 2050, 2060, 2070].includes(e)) {
            setMandatoryHaz(false)
            setHazData([
              { param_value: 'haz', param_description: 'Haz' },
              { param_value: 'non-haz', param_description: 'Non-haz' },
              { param_value: 'per-unit', param_description: 'Per-unit' },
              { param_value: 'per-gallon', param_description: 'Per-gallon' },
              { param_value: 'per-suit', param_description: 'Per-suit' },
              { param_value: 'per-man-hour', param_description: 'Per-man-hour' }
            ])
          } else if ([2090].includes(e)) {
            setHazData([
              {
                param_value: 'kosher certified',
                param_description: 'Kosher Certified'
              },
              {
                param_value: 'non_kosher',
                param_description: 'Non Kosher Certified'
              }
            ])
          } else {
            setMandatoryHaz(false)
            setHazData([
              { param_value: 'haz', param_description: 'Haz' },
              { param_value: 'non-haz', param_description: 'Non-haz' }
            ])
          }
        }
      })
      setService(assendingSort(services))
      setSelectedSubService(undefined)
    }
  }
  const setSubServices = e => {
    const data = localStorage.getItem('data')
    if (data) {
      let jsonParse = JSON.parse(data)
      let services = []
      jsonParse.TabParams.map(item => {
        if (item.param_key === 'sub_service') {
          if (item.parent_id === e) {
            services.push(item)
          }
        }
      })
      const s = assendingSort(services)
      setSelectedSubService(undefined)
      setSubService(s)
    }
  }

  const showService = serviceInfo => {
    setId(serviceInfo.id)
    setState({
      ...state,
      isShowService: true,
      selectedServiceToShow: serviceInfo
    })
  }
  const { users, isShowService, selectedServiceToShow } = state
  const handleChange = v => {
    switch (v[0].name[0]) {
      case 'service':
        setSelectedService(v[0].value)
        break
      case 'subService':
        setSelectedSubService(v[0].value)
        break
      case 'category':
        setHaz(v[0].value)
        break
      case 'Price':
        setPrice(v[0].value)
        break
      case 'Tax':
        setTax(v[0].value)
        break
      case 'Valid from':
        v[0].value && setValidateFrom(v[0].value)
        break
      case 'Valid To':
        v[0].value && setValidateTo(v[0].value)
        break
      case 'Remark':
        setRemark(v[0].value)
        break
      default:
        break
    }
  }
  const handleServiceGroupChange = (v, edit) => {
    setSelectedServiceGroup(v)
    if (!edit) {
      form.setFieldsValue({ service: '', subService: '', category: '' })
      handleServiceChange(null, false)
      setServices(v, true)
    } else {
      setServices(v, false)
    }
  }
  const handleServiceChange = (v, edit) => {
    setSelectedService(v)

    if (!edit) {
      if (haz) setHaz(null)
      form.setFieldsValue({ subService: '', category: '' })
    }

    setSubServices(v)
  }
  const handleSubServiceChange = (v, edit) => {
    setSelectedSubService(v)

    if (!edit) {
      if (haz) setHaz(null)
      form.setFieldsValue({ category: '' })
    }
  }

  const validate = async () => {
    if (selectedServiceGroup === null) {
      message.success({ content: `Please Select Service Group`, duration: 2 })
      return false
    } else if (selectedService === null) {
      message.success({ content: `Please Select Service`, duration: 2 })
      return false
    } else if (price === '') {
      message.success({ content: `Please Select Price`, duration: 2 })
      return false
    } else {
      return true
    }
  }
  const cancelEdit = () => {
    setFormEdit(false)
    form.resetFields()
    setSelectedServiceGroup(null)
  }
  const deleteService = () => {
    props.deleteServices(id)
    setState({
      ...state,
      isShowService: false
    })
  }

  const updateService = async () => {
    const isValid = await validate()
    if (isValid) {
      let param = {
        id: id,
        price: form.getFieldValue('Price'),
        tax: parseFloat(form.getFieldValue('Tax')),
        // equipment_type_id:parseInt(form.getFieldValue('equipment_type')),
        remark: form.getFieldValue('Remark')
      }
      const callback = call => {
        if (call) {
          form.resetFields()
          setSelectedServiceGroup(null)
          setState({
            ...state,
            isShowService: false
          })
          setVisible(false)
          setFormEdit(false)
        }
      }
      props.updateServices(param, callback)
    }
  }
  const addService = async () => {
    const dt = new Date()
    const isValid = await validate()
    if (isValid) {
      let category = ''
      if (form.getFieldValue('category') === 'non_kosher') {
        category = ''
      } else if (form.getFieldValue('category') === '') {
        category = 'independent'
      } else {
        category = form.getFieldValue('category') ?? ''
      }
      let param = {
        service_group_id: selectedServiceGroup,
        service_id: selectedService,
        sub_service_id: selectedSubService,
        // equipment_type_id:form.getFieldValue('equipment_type'),
        category: category,
        name: '',
        price: form.getFieldValue('Price'),
        tax: parseFloat(form.getFieldValue('Tax')),
        price_valid_from: validateFrom.format(),
        price_valid_till: moment(add_years(dt, 15)).format(),
        remark: form.getFieldValue('Remark')
      }

      const callback = call => {
        if (call) {
          setFormEdit(false)
          form.resetFields()
          setSelectedServiceGroup(null)
          setState({
            ...state,
            isShowService: false
          })
          setVisible(false)
        }
      }
      props.addServices(param, callback)
    }
  }
  const EquipmentCategory = () => {
    const tabParam = JSON.parse(localStorage.getItem('data'))
    const _eqCategory = []
    tabParam.TabParams &&
      tabParam.TabParams.map(data => {
        if (data.param_key === 'equipment_category') {
          _eqCategory.push({
            value: data.param_value.toString(),
            displayValue: data.param_name
          })
        }
      })
    setEqCategory(assendingEq(_eqCategory))
  }
  const dropDownEqCategory = e => {
    const tabParam = JSON.parse(localStorage.getItem('data'))
    form.setFieldsValue({ equipment_type: '' })
    const _eqType = []
    tabParam.TabParams &&
      tabParam.TabParams.map(data => {
        if (
          data.param_key === 'equipment_type' &&
          e === data.parent_id.toString()
        ) {
          _eqType.push({
            value: data.param_value.toString(),
            displayValue: data.param_name,
            parent_id: data.parent_id.toString()
          })
        }
      })
    setEqType(assendingEq(_eqType))
  }
  const dropDownEqType = e => {}
  const menu = (
    <Menu>
      <Menu.Item>Action 1</Menu.Item>
      <Menu.Item>Action 2</Menu.Item>
    </Menu>
  )

  const expandedRowRender = children => {
    const expandedRowRender = subChild => {
      const columns = [
        {
          title: 'SUB-SERVICE',
          dataIndex: 'name',
          render: (_, render) => (
            <span className='text-capitalize'>
              {render.name ? render.name : 'NA'}
            </span>
          ),
          sorter: {
            compare: (a, b) => {
              a = a?.name?.toLowerCase()
              b = b?.name?.toLowerCase()
              return a > b ? -1 : b > a ? 1 : 0
            }
          }
        },
        {
          title: 'CATEGORY',
          dataIndex: 'category',
          render: (_, render) => (
            <span className='text-capitalize'>
              {render.category ? render.category : 'NA'}
            </span>
          ),
          sorter: {
            compare: (a, b) => {
              a = a?.category?.toLowerCase()
              b = b?.category?.toLowerCase()
              return a > b ? -1 : b > a ? 1 : 0
            }
          }
        },
        // {
        //   title:'EQUIPMENT TYPE',
        //   dataIndex: 'equipment_type',
        //   render: (_, render) => (
        //     <span className="text-capitalize">{render.equipment_type?render.equipment_type:"NA"}</span>
        //   ),
        //   sorter: {
        //     compare: (a, b) => {
        //       a = a?.equipment_type?.toLowerCase();
        //       b = b?.equipment_type?.toLowerCase();
        //       return a > b ? -1 : b > a ? 1 : 0;
        //     },
        //   },
        // },
        {
          title: 'PRICE',
          dataIndex: 'price',
          render: (_, render) => <span>${NumToStr(render.price || 0, 0)}</span>,
          sorter: {
            compare: (a, b) => {
              a = a.price
              b = b.price
              return a > b ? -1 : b > a ? 1 : 0
            }
          }
        },
        {
          title: 'PRICE VALID FROM',
          dataIndex: 'p_v_f',
          render: (_, render) => (
            <span>{moment(render.price_valid_from).format('MM/DD/YYYY')}</span>
          ),
          sorter: {
            compare: (a, b) => {
              a = moment(a.price_valid_from).format('MM/DD/YYYY')
              b = moment(b.price_valid_from).format('MM/DD/YYYY')
              return a > b ? -1 : b > a ? 1 : 0
            }
          }
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          render: status => (
            <Tag
              className='text-capitalize'
              color={status === 0 ? 'red' : 'cyan'}
            >
              {status === 0 ? 'Inactive' : 'Active'}
            </Tag>
          ),
          sorter: {
            compare: (a, b) => {
              a = a.status
              b = b.status
              return a > b ? -1 : b > a ? 1 : 0
            }
          }
        },

        {
          title: '',
          dataIndex: 'actions',
          render: (_, elm) => (
            <div className='text-right'>
              <Tooltip title='View'>
                <Button
                  type='primary'
                  className='mr-2'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    showService(elm)
                  }}
                  size='small'
                />
              </Tooltip>
            </div>
          )
        }
      ]

      return (
        <Table
          style={{ margin: '5px 0px 0px 25px' }}
          columns={columns}
          className='components-table-demo-nested'
          dataSource={subChild.child ? subChild.child : []}
          pagination={false}
        />
      )
    }
    const columns = [
      {
        title: 'SERVICE',
        dataIndex: 'email',
        render: (_, render) => (
          <span className='text-capitalize'>{render.name}</span>
        ),
        sorter: {
          compare: (a, b) => {
            a = a.name.toLowerCase()
            b = b.name.toLowerCase()
            return a > b ? -1 : b > a ? 1 : 0
          }
        }
      }
    ]

    return (
      <Table
        style={{ margin: '5px 0px' }}
        columns={columns}
        dataSource={children.child ? children.child : []}
        className='components-table-demo-nested'
        expandable={{
          expandedRowRender: record => expandedRowRender(record),
          rowExpandable: record => record.child.length !== 0
        }}
        pagination={false}
      />
    )
  }

  const tableColumns = [
    {
      title: 'SERVICE GROUP',
      dataIndex: 'service_group_name',
      render: (_, render) => (
        <span className='text-capitalize'>
          <b>{render.name}</b>
        </span>
      ),

      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase()
          b = b.name.toLowerCase()
          return a > b ? -1 : b > a ? 1 : 0
        }
      }
    }
  ]
  const editServiceData = () => {
    setFormEdit(true)
    const editData = selectedServiceToShow
    const equipment_type_id = editData?.equipment_type_id
      ? editData?.equipment_type_id
      : ''
    handleServiceGroupChange(editData.service_group_id, true)
    dropDownEqCategory(FetchParentData(equipment_type_id)?.toString())
    form.setFieldsValue({
      service_group_id: editData.service_group_id,
      service: editData.service_id,
      Price: editData.price,
      Tax: editData.tax,
      equipment_category: FetchParentData(equipment_type_id)?.toString(),
      equipment_type: equipment_type_id?.toString(),
      Remark: editData.remark,
      category: editData.category,
      subService: editData?.sub_service_id
    })

    handleServiceChange(editData.service_id, true)
    setTax(editData.tax)
    setHaz(editData.category)
    setPrice(editData.price)
    setRemark(editData.remark)
    if (editData.sub_service_id) {
      handleSubServiceChange(editData.sub_service_id, true)
    }

    setState({
      ...state,
      isShowService: false
    })
    setVisible(true)
  }
  const addHandler = () => {}
  if (!loading) {
    return (
      <AppLayout>
        {console.log(price)}
        <PageHeader
          title='service master'
          button={true}
          img={Add}
          addHandler={addHandler}
          buttonContent={
            <AddService
              loading={props.loading}
              onSubmit={() => (formEdit ? updateService() : addService())}
              visible={visible}
              setVisible={setVisible}
              serviceGroup={serviceGroup}
              form={form}
              formEdit={formEdit}
              selectedServiceGroup={selectedServiceGroup}
              handleServiceGroupChange={v => handleServiceGroupChange(v, false)}
              services={service}
              selectedService={selectedService}
              handleServiceChange={v => handleServiceChange(v, false)}
              subService={subService}
              selectedSubService={selectedSubService}
              handlePriceChange={setPrice}
              handleSubServiceChange={handleSubServiceChange}
              eqCategory={eqCategory}
              dropDownEqCategory={dropDownEqCategory}
              price={price}
              tax={tax}
              remark={remark}
              haz={haz}
              hazData={hazData}
              mandatoryHaz={mandatoryHaz}
              Button={
                <>
                  <span style={{ marginRight: '7px' }}>
                    <PlusOutlined />
                  </span>
                  <span>Add New Service</span>
                </>
              }
              title={formEdit ? 'Update Service' : 'Add New Service'}
              custom={true}
              className='wash_po_add_edit_btn'
            />
          }
        />
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h3 style={{ marginBottom: 10 }}></h3>
        </div>
        <Divider />
        <Card bodyStyle={{ padding: '0px' }}>
          <Table
            className='components-table-demo-nested'
            expandable={{ expandedRowRender }}
            loading={props.loading}
            columns={tableColumns}
            dataSource={users}
            rowKey='id'
          />
        </Card>
        {/* <Divider />
        <h3>{formEdit? "Update Service":"Create Service"}</h3> */}
        {/* <div>
          <ServiceForm
            handleChange={(e) => handleChange(e)}
            form={form}
            price={price}
            tax={tax}
            onCancelEdit={()=>cancelEdit()}
            formEdit={formEdit}
            remark={remark}
            onSubmit={() => formEdit?updateService():addService()}
            loading={props.loading}
            selectedServiceGroup={selectedServiceGroup}
            selectedSubService={selectedSubService}
            handleServiceGroupChange={v=>handleServiceGroupChange(v,false)}
            handleServiceChange={(v) =>handleServiceChange(v,false)}
            serviceGroup={serviceGroup}
            dropDownEqCategory={dropDownEqCategory}
            eqCategory={eqCategory}
            eqType={eqType}
            services={service}
            selectedService={selectedService}
            selectedSubService={selectedSubService}
            handleSubServiceChange={handleSubServiceChange}
            subService={subService}
            haz={haz}
            hazData={hazData}
            mandatoryHaz={mandatoryHaz}
          />
        </div> */}
        <ServiceView
          data={selectedServiceToShow}
          visible={isShowService}
          onClickEdit={() => editServiceData()}
          onClickDelete={() => deleteService()}
          close={() => {
            setState({
              ...state,
              isShowService: false,
              selectedServiceToShow: null
            })
          }}
        />
      </AppLayout>
    )
  } else {
    return (
      <div className='spinner-main'>
        <Spin />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  loading: state.service.loading,
  user_status: state.user.user_status,
  newUserData: state.user.newUserData,
  updateUserData: state.user.updateUserData,
  userId: state.user.userId,
  list: state.service.list
})

const mapDispatchToProps = dispatch => {
  return {
    addServices: (data, callback) => dispatch(addServices(data, callback)),
    updateServices: (data, callback) =>
      dispatch(updateServices(data, callback)),
    getServiceList: () => dispatch(getServiceList()),
    updateStatus: data => dispatch(updateStatus(data)),
    deleteServices: data => dispatch(deleteServices(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Service)
